import "../pages/portfolio.css"
import React from "react";
  
const About = () => {
  return (
    <div>
      <h1>About Page</h1>
    </div>
  );
};
  
export default About;